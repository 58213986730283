import React, { useEffect, useState } from "react";
import LHLogo from "../assets/LHLogo.svg";
import person from "../assets/person_outline.svg";
import { useAuth } from "../context/AuthContext";
import FormattedDateTime from "./FormattedDateTime";
import { useMainDashboardContext } from "../context/MainDashboardProvider";
import { getDayName } from "../utils/common/getDayName";

const Header = ({ onHeaderAlertClick }) => {
  const date = new Date();

  const nowDate = `${date.getFullYear()}.${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}.${String(date.getDate()).padStart(2, "0")}.${getDayName(date)}`;

  const newTime = `${String(date.getHours()).padStart(2, "0")}:${String(
    date.getMinutes()
  ).padStart(2, "0")}`;

  const [warningAlert, setWarningAlert] = useState(null);
  const { userName, logout } = useAuth();
  const { alerts } = useMainDashboardContext();

  useEffect(() => {
    setWarningAlert(alerts?.data?.find((item) => !item.abn_acknowledged));
  }, [alerts]);

  const handleAlertClick = () => {
    if (typeof onHeaderAlertClick === "function" && warningAlert) {
      onHeaderAlertClick(warningAlert);
    } else {
      console.error(
        "onAlertClick is not a function or currentAlert is missing"
      );
    }
  };

  return (
    <header className="header">
      <div className="header-left">
        <img src={LHLogo} alt="Logo" className="logo" />
        <span className="location-title">부천 도당 행복주택</span>
      </div>
      <div className="header-center">
        <span className="main-title">입주세대 헬스케어 대시보드</span>
      </div>
      <div className="header-right">
        {alerts?.data?.length > 0 && (
          <div className="alert-info" onClick={handleAlertClick}>
            <div className="alert-info-left">
              <span className="alert-text">{warningAlert?.address}</span>
              <span className="alert-text">{warningAlert?.alert_title}</span>
              <span className="alert-description">
                {warningAlert?.abn_details.heart_rage_range ||
                  warningAlert?.abn_additional_data}
              </span>
            </div>

            <div className="alert-info-right">
              <FormattedDateTime
                dateTimeString={warningAlert?.abn_alert_time}
                color={"#fff"}
              />
            </div>
          </div>
        )}
        <div className="right">
          <div className="date-time">
            <span className="date">{nowDate}</span>
            <span className="time">{newTime}</span>
          </div>
          <div
            style={{
              display: "flex",

              alignItems: "center",
            }}
          >
            <img src={person} alt="personIcon" className="logo" />
            <span style={{ fontSize: 20 }}>{userName}</span>
            <button className="Logout" onClick={logout}>
              <span>로그아웃</span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
